<template>
  <div>
    <div class="card">
      <h3 class="card-title">Crear noticia</h3>
      <div class="cols2">
        <pd-input
          type="text"
          id="titulo"
          v-model="titulo"
          placeholder="Titulo"
          label="Título"
        ></pd-input>
        <pd-input
          id="tipo"
          placeholder="Tipo"
          :select="true"
          label="Tipo"
          v-model="tipo"
        >
          <template slot="options">
            <option :value="null">Seleccione un tipo</option>
            <option
              v-for="tipoNoticia in tipos"
              :key="tipoNoticia.value"
              :value="tipoNoticia.value"
            >
              {{ tipoNoticia.text }}
            </option>
          </template>
        </pd-input>
        <pd-input
          v-model="desde"
          id="desde"
          label="Desde"
          placeholder="Desde"
          type="date"
        ></pd-input>
        <pd-input
          v-model="hasta"
          id="desde"
          label="Hasta"
          placeholder="Hasta"
          type="date"
        ></pd-input>
      </div>
      <pd-input
        type="file"
        id="archivo"
        v-model="archivo"
        placeholder="Archivo"
        label="PDF"
      ></pd-input>
      <div class="form-group my-1">
        <label class="form-label">Contenido</label>
        <quill-editor
          v-model="contenido"
          :options="editorOption"
        ></quill-editor>
      </div>
      <div class="form-group my-1">
        <label class="form-label">Estado</label>
        <select id="publicado" class="form-control" v-model="publicado">
          <option :value="true">Publicado</option>
          <option :value="false">Sin publicar</option>
        </select>
      </div>
      <button class="btn btn-primary" @click="save">Guardar</button>
    </div>
    <div class="noticias">
      <div class="card" :class="classNoticia">
        <h4 class="card-title">{{ titulo }}</h4>
        <div v-html="contenido"></div>
      </div>
    </div>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import { NoticiasServices } from "../services/NoticiasServices";
import Swal from "sweetalert2";
export default {
  name: "CrearNoticia",
  data: () => {
    return {
      titulo: "",
      contenido: "",
      archivo: null,
      tipo: null,
      desde: null,
      hasta: null,
      editorOption: {
        theme: "snow",
      },
      tipos: [
        { value: "remember", text: "Recordatorio" },
        { value: "novedad", text: "Novedad" },
        { value: "felicitaciones", text: "Felicitación" },
        { value: "luto", text: "Luto" },
        { value: "interes", text: "Información de interés" },
        { value: "formulario", text: "Formulario" },
      ],
      publicado: true
    };
  },
  components: {
    quillEditor,
  },
  watch: {
    desde() {
      if (this.desde) {
        let fecha_desde = new Date(this.desde);
        fecha_desde.setDate(fecha_desde.getDate() + 7);
        this.hasta = fecha_desde.toISOString().slice(0, 10);
      }
    },
  },
  computed: {
    classNoticia() {
      let clase = "";
      if (this.tipo) {
        clase = `card-${this.tipo}`;
      }
      return clase;
    },
  },
  created() {
    const today = new Date();
    this.desde = today.toISOString().slice(0, 10);
    this.hasta = today.toISOString().slice(0, 10);
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("contenido", this.contenido);
      const archivo = document.querySelector("input#archivo");
      formData.append("archivo", archivo.files[0]);
      formData.append("tipo", this.tipo);
      formData.append("desde", this.desde);
      formData.append("hasta", this.hasta);
      formData.append("publicado", this.publicado);

      NoticiasServices.api
        .save(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire("Éxito", "Se guardó correcamente", "success");
            this.$router.push({ name: "ListarNoticias" });
          } else {
            Swal.fire("Error", "No se pudo guardar", "error");
          }
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    },
  },
};
</script>